import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import {
    Auth0ProviderWithHistory,
    ProtectedRoute,
    ThemeFactoryWrapper,
    useAuth0Context,
    useUserApi,
    useUserStore,
    GlobalLoader,
    GlobalErrorMessage,
    CookieEnabledWrapper,
} from '@floq/floq-shared';
import { Layout } from './components/navigation/layout';
import Home from './scenes/home/components/Home';
import { NotFound } from './scenes/not-found';
import { AUTH0, FLOQ_STUDIO_URL } from './config';
import FloqMainStoresProvider from './stores/FloqMainStoresProvider';

const queryClient = new QueryClient();

const App = () => {
    const { authenticated, userData } = useAuth0Context();
    const { user, setUser } = useUserStore();
    const { getUserProfile } = useUserApi();

    const { data, error } = useQuery('getUserProfile', getUserProfile, {
        enabled: authenticated && !user,
        retry: 0,
    });

    useEffect(() => {
        if (!authenticated) {
            setUser(null);
        }
    }, [authenticated, setUser]);

    useEffect(() => {
        const { user } = data || {};

        if (user) {
            setUser(user);
            const { floqs } = user;
            if (floqs && floqs.length === 1) {
                const isLocalhost = FLOQ_STUDIO_URL.includes('localhost');
                const [floq] = floqs;
                const studioUrl = FLOQ_STUDIO_URL.replace(/http(s)?:\/\//, '');
                const https = `http${isLocalhost ? '' : 's'}://`;
                window.location.href = `${https}${floq.name}.${studioUrl}`;
            }
        }
    }, [data, setUser]);

    if (error) {
        return <GlobalErrorMessage />;
    }

    if (authenticated && !user) {
        return <GlobalLoader />;
    }

    return (
        <div className="App">
            <div className="page-layout__content">
                <Routes>
                    <Route path="/" element={<ProtectedRoute component={Layout}></ProtectedRoute>}>
                        <Route index element={<Home />} />
                        <Route path="*" element={<NotFound />} />
                    </Route>
                </Routes>
            </div>
        </div>
    );
};

const AppWithProviders = () => (
    <ThemeFactoryWrapper>
        <CookieEnabledWrapper>
            <QueryClientProvider client={queryClient}>
                <Router>
                    <FloqMainStoresProvider>
                        <Auth0ProviderWithHistory
                            domain={AUTH0.domain}
                            client_id={AUTH0.client_id}
                            organization={AUTH0.organization}
                            audience={AUTH0.audience}
                        >
                            <App />
                        </Auth0ProviderWithHistory>
                    </FloqMainStoresProvider>
                </Router>
            </QueryClientProvider>
        </CookieEnabledWrapper>
    </ThemeFactoryWrapper>
);

export default AppWithProviders;
