import { useAxios } from '../auth/index';

interface UpdateUserProfilePayload {
    firstName: string;
    lastName: string;
    email?: string;
    profileImage?: string;
}

export const useUserApi = () => {
    const { get, put } = useAxios();

    const getUserProfile = () => get('user/profile');

    const updateUserProfile = (data: UpdateUserProfilePayload) => put('user/profile', data);

    return { getUserProfile, updateUserProfile };
};
