import React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';
import Button from '@mui/material/Button';
import { UI_GREY_2, Floq } from '@floq/floq-shared';
import { FLOQ_STUDIO_URL } from '../../../config';

const Card = styled.div`
    display: flex;
    padding: 24px;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1), 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 0 1px 0 rgba(0, 0, 0, 0.15);
    background-color: #fff;
    margin-bottom: 24px;
`;

const ImageWrapper = styled.div`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: ${UI_GREY_2};
`;

const ImagePlaceholder = styled.img`
    width: 60px;
    aspect-ratio: 1;
    border-radius: 50%;
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 24px;
    text-align: left;
`;

const ButtonWrapper = styled.div`
    margin-left: auto;
    align-self: center;
    min-width: max-content;
    white-space: nowrap;
`;

type StudioCardProps = {
    item: Floq;
};

const StudioCard = ({ item }: StudioCardProps) => {
    const onClick = () => {
        const isLocalhost = FLOQ_STUDIO_URL.includes('localhost');
        const studioUrl = FLOQ_STUDIO_URL.replace(/http(s)?:\/\//, '');
        const https = `http${isLocalhost ? '' : 's'}://`;
        window.open(`${https}${item.name}.${studioUrl}`, '_blank');
    };

    return (
        <Card>
            <ImageWrapper>
                <ImagePlaceholder src={item.imageUrl} />
            </ImageWrapper>
            <TextWrapper>
                <Typography variant="h3">{item.displayName}</Typography>
                <Typography variant="body1">{`${item.name}.studio.floq.live`}</Typography>
            </TextWrapper>
            <ButtonWrapper>
                <Button variant="contained" startIcon={<LaunchIcon />} onClick={onClick}>
                    Open studio
                </Button>
            </ButtonWrapper>
        </Card>
    );
};

export default StudioCard;
