import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { Loader, useAuth0Context, AuthenticationButton } from '@floq/floq-shared';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import floqLogo from '../../assets/images/floq-logo-by-cc@2x.png';
import styled from 'styled-components';

const FloqLogoImage = styled.img`
    height: 80px;
    padding: 10px;
`;

export const Layout = () => {
    const { loading } = useAuth0Context();

    if (loading) {
        return <Loader />;
    }
    return (
        <Box sx={{ display: 'flex', width: '100vw', height: '100vh' }}>
            <CssBaseline />
            <AppBar elevation={0} position="fixed" sx={{ bgcolor: 'white' }}>
                <Toolbar sx={{ justifyContent: 'space-between' }}>
                    <FloqLogoImage src={floqLogo} alt="Banner" />
                    <AuthenticationButton />
                </Toolbar>
            </AppBar>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', marginTop: '64px', overflow: 'scroll' }}
            >
                <Outlet />
            </Box>
        </Box>
    );
};
