import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { useUserStore } from '../../stores';
import { useUserApi } from '../../api';
import { useAuth0Context } from '../../auth';
import { FormRow } from '../layouts';
import { TextInput } from '../inputs/TextInput';
import { Modal } from '../modal/Modal';
import { Loader } from '../loader/Loader';
import Box from '@mui/material/Box';

const ValidationSchema = Yup.object().shape({
    profileImage: Yup.mixed().optional().label('Profile image'),
    email: Yup.string().email().required().label('Email'),
    firstName: Yup.string().min(2).max(30).required().label('First name'),
    lastName: Yup.string().min(2).max(30).required().label('Last name'),
});

interface UserProfileModalProps {
    onClose: () => void;
    intro?: boolean;
}

export const UserProfileModal = ({ onClose, intro }: UserProfileModalProps) => {
    const queryClient = useQueryClient();
    const { updateUserProfile: updateUserProfileRequest } = useUserApi();
    const { user, setUser } = useUserStore();
    const { setUserName } = useAuth0Context();

    const title = intro ? 'Complete your profile' : 'Edit profile';

    const { mutateAsync: updateUserProfile, isLoading } = useMutation(updateUserProfileRequest, {
        onSuccess: () => queryClient.invalidateQueries('updateUserProfile'),
    });
    const { values, setFieldValue, setFieldTouched, errors, touched, submitForm } = useFormik({
        initialValues: {
            profileImage: '',
            email: '',
            firstName: '',
            lastName: '',
        },
        validationSchema: ValidationSchema,
        onSubmit: async (values) => {
            // TODO: Include profileImage after setting up S3 bucket
            const { firstName, lastName } = values;
            const { user: updatedUser } = await updateUserProfile({ firstName, lastName });
            if (user) {
                setUser({
                    ...user,
                    firstName: updatedUser.firstName,
                    lastName: updatedUser.lastName,
                });
            }

            setUserName?.({
                given_name: updatedUser.firstName,
                family_name: updatedUser.lastName,
            });
            onClose();
        },
    });

    useEffect(() => {
        if (!user) {
            return;
        }

        setFieldValue('email', user.email);
        setFieldValue('firstName', user.firstName);
        setFieldValue('lastName', user.lastName);
    }, [user]);

    return (
        <Modal
            size="xs"
            title={title}
            onClose={onClose}
            actions={
                intro
                    ? [
                          {
                              label: 'Continue',
                              onClick: submitForm,
                              disabled: isLoading,
                          },
                      ]
                    : [
                          { label: 'Save', onClick: submitForm, disabled: isLoading },
                          { label: 'Cancel', onClick: onClose, color: 'secondary' },
                      ]
            }
        >
            {/*<FormRow centered>*/}
            {/*    <AvatarPicker*/}
            {/*        value={values.profileImage}*/}
            {/*        onChange={(file: File) => setFieldValue('profileImage', file)}*/}
            {/*    />*/}
            {/*</FormRow>*/}

            <FormRow>
                <TextInput
                    label="Email address"
                    value={values.email}
                    error={touched.email ? errors.email : undefined}
                    onChange={(value) => setFieldValue('email', value)}
                    onBlur={() => setFieldTouched('email')}
                    disabled
                />
            </FormRow>

            <FormRow>
                <TextInput
                    label="First name"
                    value={values.firstName}
                    error={touched.firstName ? errors.firstName : undefined}
                    onChange={(value) => setFieldValue('firstName', value)}
                    onBlur={() => setFieldTouched('firstName')}
                />
                <Box sx={{ mr: '8px' }} />
                <TextInput
                    label="Last name"
                    value={values.lastName}
                    error={touched.lastName ? errors.lastName : undefined}
                    onChange={(value) => setFieldValue('lastName', value)}
                    onBlur={() => setFieldTouched('lastName')}
                />
            </FormRow>
            {isLoading && <Loader />}
        </Modal>
    );
};
