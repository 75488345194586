import React, { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { User } from '../models/User';

type StoreUser = User | null;

interface UserStoreModel {
    user: StoreUser;
    setUser: Dispatch<SetStateAction<StoreUser>>;
}

const INITIAL_STATE = {
    user: null,
    setUser: () => {},
};

const UserStoreContext = createContext<UserStoreModel>(INITIAL_STATE);

interface UserStoreProviderProps {
    children?: React.ReactNode;
}

export const UserStoreProvider = ({ children }: UserStoreProviderProps) => {
    const [user, setUser] = useState<StoreUser>(null);

    const value = {
        user,
        setUser,
    };

    return <UserStoreContext.Provider value={value}>{children}</UserStoreContext.Provider>;
};

export const useUserStore = () => useContext(UserStoreContext);
