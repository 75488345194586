import React from 'react';

const ConferenceCompassIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_24_113)">
            <rect x="0.5" y="0.500122" width="31" height="31" rx="15.5" fill="white" />
            <path
                d="M15.9926 10.7238C12.766 10.7238 10.1516 13.3381 10.1516 16.5648C10.1516 19.7915 12.766 22.4058 15.9926 22.4058C19.2193 22.4058 21.8337 19.7915 21.8337 16.5648C21.8337 13.3381 19.2193 10.7238 15.9926 10.7238Z"
                fill="black"
                fill-opacity="0.87"
            />
            <path
                d="M16.0001 5.00006C9.92436 5.00006 5 9.92442 5 16.0001C5 22.0758 9.92436 27.0002 16.0001 27.0002C22.0758 27.0002 27.0001 22.0758 27.0001 16.0001C27.0001 9.92442 22.0758 5.00006 16.0001 5.00006ZM23.4581 17.3201C23.0291 20.8585 20.2901 23.6782 16.7444 24.0302L15.9781 25.8085L15.2411 24.0302C11.699 23.6782 8.87935 20.8585 8.52735 17.3165L6.75268 16.5795L8.52735 15.8168C8.85369 12.5204 11.3177 9.85109 14.5114 9.20942L15.9927 5.72973L17.4741 9.20575C20.6678 9.84375 23.1318 12.5131 23.4581 15.8058L25.2438 16.5721L23.4581 17.3201Z"
                fill="black"
                fill-opacity="0.87"
            />
            <rect x="0.5" y="0.500122" width="31" height="31" rx="15.5" stroke="#DDDDDD" />
        </g>
        <defs>
            <clipPath id="clip0_24_113">
                <rect y="0.00012207" width="32" height="32" rx="6" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default ConferenceCompassIcon;
