import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import Typography from '@mui/material/Typography';

import { Loader, SearchBar, Floq, useUserApi, useAuth0Context, UserProfileModal } from '@floq/floq-shared';

import floqBanner from '../../../assets/images/floq-banner.png';
import StudioCard from './StudioCard';

const FloqBannerImage = styled.img`
    width: 100%;
`;

const ListWrapper = styled.div`
    max-width: 600px;
    margin: 32px auto;
`;

const LoaderWrapper = styled.div`
    margin: 12px auto;
    width: fit-content;
`;

const SearchBarWrapper = styled.div`
    width: 50%;
    margin-bottom: 24px;
`;

const Home = () => {
    const { userData } = useAuth0Context();
    const userNotSynchronized = !userData?.given_name;
    const [searchValue, setSearchValue] = useState('');
    const [showCompleteUserProfileModal, setShowCompleteUserProfileModal] = useState<boolean>(userNotSynchronized);
    const { getUserProfile } = useUserApi();

    const { data, isLoading } = useQuery('floqs', () => getUserProfile());
    const floqs: Floq[] = useMemo(() => {
        return data?.user?.floqs || [];
    }, [data]);

    const filteredSocieties = useMemo(
        () =>
            floqs
                .filter((floq: Floq) => {
                    const parts = floq.name.split('-');
                    const searchTerm = `${floq.displayName} ${floq.description} ${parts.join(' ')}`;
                    return searchTerm.toLowerCase().includes(searchValue.trim().toLowerCase());
                })
                .sort((a, b) => a.displayName.localeCompare(b.displayName)),
        [floqs, searchValue],
    );

    const closeCompleteUserProfileModal = async () => {
        setShowCompleteUserProfileModal(false);
    };

    return (
        <>
            <FloqBannerImage src={floqBanner} alt="Banner" />

            {showCompleteUserProfileModal && <UserProfileModal intro onClose={closeCompleteUserProfileModal} />}

            <ListWrapper>
                <SearchBarWrapper>
                    <SearchBar value={searchValue} onChange={setSearchValue} />
                </SearchBarWrapper>

                {isLoading && (
                    <LoaderWrapper>
                        <Loader />
                    </LoaderWrapper>
                )}

                {filteredSocieties.map((floq) => (
                    <StudioCard key={`floq-${floq.id}`} item={floq} />
                ))}

                {filteredSocieties.length === 0 && <Typography variant="body1">No results.</Typography>}
            </ListWrapper>
        </>
    );
};

export default Home;
