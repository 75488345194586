import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

interface GlobalLoaderProps {
    open?: boolean;
}

export const GlobalLoader = ({ open = true }: GlobalLoaderProps) => {
    return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};
