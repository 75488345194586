import styled from 'styled-components';
import { COLOR_WHITE, SECONDARY_MEDIUM } from '../../utils';

export const FormRow = styled.div`
    display: flex;
    flex: 0 0 auto;
    margin-bottom: 10px;
    ${(props: { centered?: boolean }) => props.centered && 'justify-content: center;'}
`;

export const GreyPageLayout = styled.div`
    background-color: ${SECONDARY_MEDIUM};
    width: 100%;
    height: 100%;
`;

export const PageSection = styled.div`
    box-shadow: 0 0 1px rgba(0, 0, 0, 0.15), 0 2px 8px rgba(0, 0, 0, 0.05), 0 2px 3px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: ${COLOR_WHITE};
    padding: 8px 16px;
`;
