import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppState, Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import { Auth0 } from './model';

export const Auth0Context = React.createContext<Auth0>({});

export const useAuth0Context = () => useContext(Auth0Context);

interface Auth0ProviderOptions {
    domain?: any;
    client_id?: any;
    redirect_uri?: any;
    audience?: any;
    children: React.ReactElement;
    organization?: any;
}

const OwnProvider = ({ children }: { children: React.ReactElement }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userData, setUserData] = useState({});

    const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (user) {
            setUserData(user);
        }

        setLoading(isLoading);
        setAuthenticated(isAuthenticated);
    }, [isAuthenticated, isLoading, user]);

    const getAccessToken = (options = {}): Promise<string> => {
        return getAccessTokenSilently(options);
    };

    const setUserName = (data: { given_name: string; family_name: string }) => {
        const { given_name, family_name } = data;

        setUserData({
            ...userData,
            given_name,
            family_name,
        });
    };

    return (
        <Auth0Context.Provider
            value={{
                loading,
                authenticated,
                userData,
                getAccessToken,
                setUserName,
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};

export const Auth0ProviderWithHistory = ({ children, ...initOptions }: Auth0ProviderOptions) => {
    const { domain, client_id, organization, audience } = initOptions;
    const navigate = useNavigate();
    const onRedirectCallback = (appState: AppState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && client_id)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={client_id}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            organization={organization}
            audience={audience}
            prompt={'select_account'}
        >
            <OwnProvider>{children}</OwnProvider>
        </Auth0Provider>
    );
};
