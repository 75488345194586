import React from 'react';
import Box from '@mui/material/Box';
import { UI_GREY_1 } from '../../utils';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';

export const GlobalErrorMessage = () => {
    return (
        <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
            <Box
                sx={{
                    backgroundColor: UI_GREY_1,
                    padding: 4,
                    borderRadius: 1,
                    textAlign: 'center',
                }}
            >
                <Typography variant="h4" color="error">
                    Something went wrong. Please try again.
                </Typography>
            </Box>
        </Backdrop>
    );
};
