import React from 'react';

const FacebookIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_24_122)">
            <rect x="0.5" y="0.500122" width="31" height="31" rx="15.5" fill="white" />
            <path
                d="M20.7792 17.1652L21.3493 13.5729H17.7831V11.2418C17.7831 10.259 18.2812 9.3011 19.8786 9.3011H21.5V6.24286C21.5 6.24286 20.0285 6.00012 18.6217 6.00012C15.6845 6.00012 13.7648 7.72051 13.7648 10.8351V13.5729H10.5V17.1652H13.7648V25.8491C14.4195 25.9484 15.0905 26.0001 15.774 26.0001C16.4575 26.0001 17.1285 25.9484 17.7831 25.8491V17.1652H20.7792Z"
                fill="black"
                fill-opacity="0.87"
            />
            <rect x="0.5" y="0.500122" width="31" height="31" rx="15.5" stroke="#DDDDDD" />
        </g>
        <defs>
            <clipPath id="clip0_24_122">
                <rect y="0.00012207" width="32" height="32" rx="6" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default FacebookIcon;
