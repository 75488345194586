import React from 'react';
import { default as MUIButton } from '@mui/material/Button';
import { SxProps, Theme } from '@mui/material';

type ButtonProps = {
    children: React.ReactNode;
    href?: string;
    onClick?: (event: React.MouseEvent<HTMLElement>) => void;
    color?: 'primary' | 'secondary' | 'error' | 'info';
    variant?: 'contained' | 'text' | 'outlined';
    disabled?: boolean;
    startIcon?: React.ReactNode;
    endIcon?: React.ReactNode;
    sx?: SxProps<Theme>;
};

export const Button = ({
    children,
    href,
    variant = 'contained',
    color = 'primary',
    disabled = false,
    startIcon,
    endIcon,
    onClick,
    sx = {},
}: ButtonProps) => {
    return (
        <MUIButton
            href={href}
            variant={variant}
            color={color}
            disabled={disabled}
            onClick={onClick}
            startIcon={startIcon}
            endIcon={endIcon}
            sx={sx}
        >
            {children}
        </MUIButton>
    );
};
