import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { FLOQ_SERVICE_URL } from '../config';

// We wrap Axios methods in a hook, so we can centrally handle adding auth tokens.
export const useAxios = () => {
    const { getAccessTokenSilently } = useAuth0();

    axios.interceptors.request.use(async (config: any) => {
        if (config.url.indexOf('http') === -1) {
            config.url = `${FLOQ_SERVICE_URL}/${config.url}`;
        }

        if (typeof config.headers.Authorization === 'undefined') {
            config.headers.Authorization = `Bearer ${await getAccessTokenSilently()}`;
        }
        return config;
    });

    const requestWrapper = async (requestPromise: Promise<AxiosResponse>) => {
        const response = await requestPromise;
        return response.data;
    };

    return {
        get: async (url: string, config?: AxiosRequestConfig<any> | undefined) =>
            requestWrapper(axios.get(url, config)),
        remove: async (url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) =>
            requestWrapper(axios.delete(url, { ...config, data })),
        post: async (url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) =>
            requestWrapper(axios.post(url, data, config)),
        put: async (url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) =>
            requestWrapper(axios.put(url, data, config)),
        patch: async (url: string, data?: any, config?: AxiosRequestConfig<any> | undefined) =>
            requestWrapper(axios.patch(url, data, config)),
    };
};
