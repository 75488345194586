import React, { useLayoutEffect } from 'react';

type Props = {
    ref: React.RefObject<JSX.Element | HTMLElement> | null;
    onClickOutside: () => void;
};

function assertIsNode(e: EventTarget | null): asserts e is Node {
    if (!e || !('nodeType' in e)) {
        throw new Error(`Node expected`);
    }
}

export const useOutsideAlerter = ({ ref, onClickOutside }: Props) => {
    useLayoutEffect(() => {
        const handleClickOutside = (event: MouseEvent | TouchEvent) => {
            try {
                if (!ref) {
                    return;
                }
                assertIsNode(event.target);
                const refCurrent = ref && (ref.current as unknown as Element);
                if (ref.current && !refCurrent.contains(event.target)) {
                    onClickOutside();
                }
            } catch (e) {
                console.error(e);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClickOutside, ref]);
};

export default useOutsideAlerter;
