import React, { createRef } from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { COLOR_DISABLED, COLOR_TEXT } from '../../utils/colors';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import Tooltip from '@mui/material/Tooltip';

interface TextInputProps {
    value: string;
    onChange: (value: string) => void;
    label: string;
    error?: string;
    disabled?: boolean;
    hasClearButton?: boolean;
    rows?: number;
    onFocus?: (event: React.FocusEvent) => void;
    onBlur?: (event: React.FocusEvent) => void;
    onMouseEnter?: (event: React.MouseEvent) => void;
    onMouseLeave?: (event: React.MouseEvent) => void;
    helperText?: string;
    prefix?: string;
    decorationIcon?: React.ReactNode;
    size?: 'small' | 'medium';
    endAdornment?: React.ReactNode | null;
}

export const TextInput = ({
    value,
    onChange,
    onMouseEnter,
    onMouseLeave,
    label,
    error,
    disabled = false,
    hasClearButton = false,
    rows,
    onFocus,
    onBlur,
    helperText,
    prefix,
    decorationIcon,
    endAdornment = null,
    size,
}: TextInputProps) => {
    const inputRef = createRef<HTMLTextAreaElement | HTMLInputElement>();
    const multiline = !!rows && rows > 1;

    const onChangeLocal = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = event.target;

        onChange(value);
    };

    const onClearInput = () => onChange('');

    return (
        <Box
            sx={{
                m: 0,
                flexGrow: 1,
                display: 'flex',
            }}
        >
            {decorationIcon && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        mr: '16px',
                        height: '58px',
                    }}
                >
                    {decorationIcon}
                </Box>
            )}
            <TextField
                size={size || undefined}
                inputRef={inputRef}
                value={value}
                onChange={onChangeLocal}
                label={label}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                variant="filled"
                sx={{ width: '100%' }}
                disabled={disabled}
                error={!!error}
                helperText={error || helperText}
                onFocus={onFocus}
                onBlur={onBlur}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <Stack direction="row" alignItems="center">
                            {endAdornment}
                            {!disabled && hasClearButton && value && (
                                <InputAdornment position="end" sx={{ marginRight: '0px' }}>
                                    <IconButton onClick={onClearInput}>
                                        <CloseIcon />
                                    </IconButton>
                                </InputAdornment>
                            )}
                        </Stack>
                    ),
                    disableUnderline: true,
                    startAdornment: prefix && (
                        <InputAdornment sx={{ marginRight: '0px', marginBottom: '-2px' }} position="start">
                            <Typography sx={{ color: COLOR_DISABLED }}>{prefix}</Typography>
                        </InputAdornment>
                    ),
                }}
                multiline={multiline}
                rows={rows}
            />
        </Box>
    );
};
