export const FLOQ_MAIN = '#8341EF';

export const SECONDARY_LIGHT = '#F5F5F5';
export const SECONDARY_MEDIUM = '#F0F0F0';
export const SECONDARY_DARK = '#D3D3D3';

export const UI_GREY_1 = '#f5f5f5';
export const UI_GREY_2 = '#c4c4c4';
export const UI_GREY_3 = '#d9d9d9';
export const UI_GREY_4 = '#e2e2e2';
export const UI_GREY_5 = '#666666';
export const UI_BLACK = '#2e2e36';

export const COLOR_BLACK = 'rgba(0, 0, 0)';

export const COLOR_TEXT = 'rgba(0, 0, 0, 0.87)';
export const COLOR_TEXT_MEDIUM = 'rgba(0, 0, 0, 0.6)';
export const COLOR_TEXT_LIGHT = 'rgba(0, 0, 0, 0.54)';

export const COLOR_WHITE = '#ffffff';
export const COLOR_WHITE_10 = 'rgba(255, 255, 255, 0.1)';
export const COLOR_WHITE_16 = 'rgba(255, 255, 255, 0.16)';
export const COLOR_WHITE_40 = 'rgba(255, 255, 255, 0.4)';
export const COLOR_WHITE_50 = 'rgba(255, 255, 255, 0.5)';
export const COLOR_WHITE_60 = 'rgba(255, 255, 255, 0.6)';

export const COLOR_ERROR = '#EE1060';

export const COLOR_HOVER = 'rgba(0, 0, 0, 0.04)';
export const COLOR_SELECTED = 'rgba(243,240,254)';
export const COLOR_ERROR_SELECTED = 'rgba(255,244,248)';
export const COLOR_DISABLED = 'rgba(0, 0, 0, 0.38)';

export const COLOR_SUCCESS = '#0cb464';
