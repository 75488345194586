import React, { ComponentProps, FC } from 'react';

/**
 * Combines multiple store providers
 */
export const combineStoreProviders = (...components: FC[]): FC => {
    return components.reduce(
        (AccumulatedComponents, CurrentComponent) => {
            return ({ children }: ComponentProps<FC>): JSX.Element => {
                return (
                    <AccumulatedComponents>
                        <CurrentComponent>{children}</CurrentComponent>
                    </AccumulatedComponents>
                );
            };
        },
        ({ children }) => <>{children}</>,
    );
};
