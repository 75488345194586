import React, { ComponentType } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Loader } from '../components/loader/Loader';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    height: 500px;
    width: 100%;
    align-items: center;
    justify-content: center;
`;

interface ProtectedRouteProps {
    component: ComponentType;
}

export const ProtectedRoute = ({ component }: ProtectedRouteProps) => {
    const params = new URLSearchParams(window.location.search);

    // Accept user invitation flow;
    const organization = params.get('organization');
    const invitation = params.get('invitation');

    const Cp = withAuthenticationRequired(component, {
        onRedirecting: () => (
            <Container>
                <Loader />
            </Container>
        ),
        ...(organization && invitation && { loginOptions: { organization, invitation } }),
    });
    return <Cp></Cp>;
};
