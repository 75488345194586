import React from 'react';
import { UI_GREY_1 } from '../../utils';
import { Tabs, TabsProps } from '@mui/material';
import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';

const themeStructure: ThemeOptions = {
    components: {
        MuiTabs: {
            styleOverrides: {
                root: {
                    height: 40,
                    minHeight: 40,
                    borderRadius: 6,
                    backgroundColor: UI_GREY_1,
                    alignItems: 'center',
                    padding: 4,
                },
                scroller: {
                    height: 40,
                    transform: 'translateY(4px)',
                },
                indicator: {
                    color: 'transparent',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    height: 32,
                    minHeight: 32,
                    padding: '4px 12px',
                    borderRadius: 6,
                    textTransform: 'none',
                    '&.Mui-selected': {
                        backgroundColor: '#fff',
                        color: '#000',
                        boxShadow:
                            '0 2px 3px 0 rgba(0, 0, 0, 0.1), 0 2px 8px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 0, 0, 0.15)',
                    },
                },
            },
        },
    },
};

const theme = createTheme(themeStructure);

export const FloqTabs = ({ children, ...rest }: TabsProps) => {
    return (
        <ThemeProvider theme={theme}>
            <Tabs {...rest}>{children}</Tabs>
        </ThemeProvider>
    );
};
