import React from 'react';

const LinkedinIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_24_129)">
            <rect x="0.5" y="0.500122" width="31" height="31" rx="15.5" fill="white" />
            <path d="M11.0253 12.625H7V25H11.0253V12.625Z" fill="black" fill-opacity="0.87" />
            <path
                d="M21.9894 12.7701C21.9467 12.7568 21.9062 12.7422 21.8613 12.7296C21.8071 12.7173 21.7533 12.707 21.6981 12.6982C21.4845 12.6554 21.2501 12.625 20.9758 12.625C18.6292 12.625 17.1406 14.3318 16.6504 14.991V12.625H12.6251V25H16.6503V18.2501C16.6503 18.2501 19.6921 14.0133 20.9758 17.1249V25H25V16.6492C25 14.7793 23.7185 13.2212 21.9894 12.7701Z"
                fill="black"
                fill-opacity="0.87"
            />
            <path
                d="M10.9376 8.96884C10.9376 10.0563 10.056 10.9376 8.96887 10.9376C7.88164 10.9376 7.00006 10.0563 7.00006 8.96884C7.00006 7.88158 7.88164 7.00012 8.96887 7.00012C10.056 7.00012 10.9376 7.88158 10.9376 8.96884Z"
                fill="black"
                fill-opacity="0.87"
            />
            <rect x="0.5" y="0.500122" width="31" height="31" rx="15.5" stroke="#DDDDDD" />
        </g>
        <defs>
            <clipPath id="clip0_24_129">
                <rect y="0.00012207" width="32" height="32" rx="6" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default LinkedinIcon;
