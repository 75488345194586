import React, { useCallback, useEffect, useRef, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import styled from 'styled-components';
import { COLOR_TEXT_MEDIUM, UI_GREY_1 } from '../../utils';
import { Stack } from '@mui/material';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';
import ClearIcon from '@mui/icons-material/Clear';

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const CloseIconWrapper = styled(IconWrapper)`
    justify-self: flex-end;
    cursor: pointer;
`;

const StyledInputBase = styled(InputBase)`
    margin-left: 4px;
    flex: 1;

    & .MuiInputBase-input {
        padding: 0;
        line-height: 40px;
        font-size: 15px;
    }
`;

type SearchBarProps = {
    value: string | number;
    onlyEnterPress?: boolean;
    onChange: (value: any) => void;
    fullWidth?: boolean;
    noHover?: boolean;
};

export const SearchBar = ({
    value,
    onChange,
    onlyEnterPress = false,
    fullWidth = false,
    noHover = false,
}: SearchBarProps) => {
    const [active, setActive] = useState(false);
    const [query, setQuery] = useState<string | number>('');
    const wrapperRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const isContainerSelected = active || value;

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
        if (event.key === 'Enter' && onlyEnterPress) {
            onChange(query);
        }
    };

    useEffect(() => {
        if (value) {
            setQuery(value);
        } else {
            setQuery('');
        }
    }, [value]);

    const onChangeLocal = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = event.target;
        setQuery(value);
        if (!onlyEnterPress) {
            onChange(value);
        } else {
            if (!value) {
                onChange(value);
            }
        }
    };

    const onClearClick = useCallback(() => {
        setQuery('');
        onChange('');
        setActive(false);
        if (inputRef && inputRef.current) {
            inputRef.current.blur();
        }
    }, [setQuery, onChange, setActive, inputRef?.current]);

    const onWrapperClick = () => {
        setActive(true);
        if (inputRef && inputRef.current) {
            inputRef.current.click();
        }
    };

    useOutsideAlerter({ ref: wrapperRef, onClickOutside: () => setActive(false) });

    let width = isContainerSelected ? '250px' : 100;
    if (fullWidth) {
        width = '100%';
    }

    return (
        <Stack
            ref={wrapperRef}
            direction="row"
            height={40}
            borderRadius={10}
            sx={{
                backgroundColor: isContainerSelected ? UI_GREY_1 : !noHover ? 'transparent' : UI_GREY_1,
                width: width,
                transition: 'width 400ms',
                ':hover': {
                    backgroundColor: UI_GREY_1,
                },
            }}
            onClick={onWrapperClick}
            px={1}
        >
            <IconWrapper>
                <SearchIcon />
            </IconWrapper>
            <StyledInputBase
                ref={inputRef}
                placeholder="Search"
                inputProps={{ 'aria-label': 'search' }}
                value={query}
                onChange={onChangeLocal}
                onKeyDown={handleKeyDown}
            />
            {query && active && (
                <CloseIconWrapper onClick={onClearClick}>
                    <ClearIcon style={{ color: COLOR_TEXT_MEDIUM }} />
                </CloseIconWrapper>
            )}
        </Stack>
    );
};
