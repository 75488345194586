import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Logout from '@mui/icons-material/Logout';
import HelpIcon from '@mui/icons-material/Help';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { SECONDARY_MEDIUM, SECONDARY_DARK, COLOR_TEXT } from '../utils/colors';
import { useUserStore } from '../stores/UserStore';
import { UserProfileModal } from '../components/user/UserProfileModal';
import { Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import TwitterIcon from '../assets/social/TwitterIcon';
import ConferenceCompassIcon from '../assets/social/ConferenceCompassIcon';
import FacebookIcon from '../assets/social/FacebookIcon';
import LinkedinIcon from '../assets/social/LinkedinIcon';

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();
    return <button onClick={() => loginWithRedirect()}>Log In</button>;
};

export const AuthenticationButton = () => {
    const [showEditUserProfileModal, setShowEditUserProfileModal] = useState<boolean>(false);
    const [hoverId, setHoverId] = useState<null | string>(null);
    const { isAuthenticated, logout } = useAuth0();
    const { user } = useUserStore();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [aboutThisPlatformAnchorEl, setAboutThisPlatformAnchorEl] = React.useState<null | HTMLElement>(null);
    const aboutThisPlatformOpen = Boolean(aboutThisPlatformAnchorEl);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAboutThisPlatformMenuClick = () => {
        setAboutThisPlatformAnchorEl(anchorEl);
    };
    const handleAboutThisPlatformMenuClose = () => {
        setAboutThisPlatformAnchorEl(null);
    };

    if (user && isAuthenticated) {
        return (
            <>
                {showEditUserProfileModal && (
                    <UserProfileModal
                        onClose={() => {
                            setShowEditUserProfileModal(false);
                        }}
                    />
                )}
                <Stack direction="row">
                    <Tooltip title="Open support portal">
                        <a
                            href="https://knowledge.conferencecompass.com/for-organisers"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Button
                                disableElevation
                                onClick={() => console.log('LOGGER')}
                                style={{ marginRight: 16 }}
                                sx={{
                                    padding: 0,
                                    minWidth: 40,
                                    borderRadius: '20px',
                                    fontWeight: '500',
                                    backgroundColor: SECONDARY_MEDIUM,
                                    textTransform: 'none',
                                    color: 'rgba(0, 0, 0, 0.87)',
                                    height: '40px',
                                    '&:hover': {
                                        backgroundColor: SECONDARY_DARK,
                                    },
                                }}
                                variant="contained"
                            >
                                <HelpIcon />
                            </Button>
                        </a>
                    </Tooltip>
                    <Button
                        disableElevation
                        onClick={handleClick}
                        sx={{
                            marginLeft: 'auto',
                            borderRadius: '20px',
                            fontWeight: '500',
                            backgroundColor: SECONDARY_MEDIUM,
                            textTransform: 'none',
                            color: 'rgba(0, 0, 0, 0.87)',
                            height: '40px',
                            '&:hover': {
                                backgroundColor: SECONDARY_DARK,
                            },
                        }}
                        variant="contained"
                        startIcon={
                            <Avatar
                                alt={`${user.firstName + user.lastName}`}
                                src={''}
                                sx={{ width: 34, height: 34, marginLeft: '-8px' }}
                            >
                                {user.firstName?.[0] + user.lastName?.[0]}
                            </Avatar>
                        }
                        endIcon={<ArrowDropDown />}
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        {`${user.firstName} ${user.lastName}`}
                    </Button>
                </Stack>
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            padding: 1,
                            overflow: 'visible',
                            width: 400,
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem
                        sx={{ p: 2, minHeight: 72, height: 72 }}
                        onClick={() => {
                            setShowEditUserProfileModal(true);
                        }}
                    >
                        <ListItemAvatar sx={{ width: 56, height: 56, mx: 1 }}>
                            <Avatar
                                sx={{ minHeight: 56, minWidth: 56 }}
                                alt={`${user.firstName + user.lastName}`}
                                src={''}
                            >
                                {user.firstName?.[0] + user.lastName?.[0]}
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primaryTypographyProps={{ variant: 'h2' }}
                            secondaryTypographyProps={{ variant: 'subtitle1', color: COLOR_TEXT }}
                            primary={`${user.firstName} ${user.lastName}`}
                            secondary="Edit your profile"
                        />
                    </MenuItem>
                    <Divider variant="middle" />
                    <MenuItem sx={{ minHeight: 56, height: 56 }} onClick={handleAboutThisPlatformMenuClick}>
                        <ListItemIcon>
                            <InfoOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: 'h4' }}
                            secondaryTypographyProps={{ variant: 'subtitle1', color: COLOR_TEXT }}
                            primary="About this platform"
                            secondary="Legal information, Release notes"
                        />
                        <ListItemIcon style={{ minWidth: 16, width: 16 }}>
                            <ArrowForwardIosOutlinedIcon sx={{ fontSize: 16 }} />
                        </ListItemIcon>
                    </MenuItem>
                    <Divider variant="middle" />
                    <MenuItem
                        sx={{ minHeight: 40, height: 40 }}
                        onClick={() =>
                            logout({
                                returnTo: window.location.origin,
                            })
                        }
                    >
                        <ListItemIcon>
                            <Logout />
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'h4' }} primary="Sign out" />
                    </MenuItem>
                </Menu>
                <Menu
                    anchorEl={aboutThisPlatformAnchorEl}
                    id="about-this-platform-menu"
                    open={aboutThisPlatformOpen}
                    onClose={handleAboutThisPlatformMenuClose}
                    PaperProps={{
                        elevation: 0,
                        sx: {
                            pb: 3,
                            overflow: 'visible',
                            width: 400,
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                                width: 32,
                                height: 32,
                                ml: -0.5,
                                mr: 1,
                            },
                        },
                    }}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem sx={{ minHeight: 56, height: 56 }}>
                        <ListItemIcon onClick={handleAboutThisPlatformMenuClose}>
                            <ArrowBackOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText
                            primaryTypographyProps={{ variant: 'h4' }}
                            primary="About this platform"
                            sx={{ textAlign: 'center' }}
                        />
                        <ListItemIcon onClick={handleAboutThisPlatformMenuClose} style={{ minWidth: 20, width: 20 }}>
                            <CloseOutlinedIcon />
                        </ListItemIcon>
                    </MenuItem>
                    <Divider variant="middle" />
                    <Link
                        href="https://www.iubenda.com/terms-and-conditions/32505123"
                        target="_blank"
                        onMouseEnter={() => setHoverId('TOS')}
                        onMouseLeave={() => setHoverId(null)}
                    >
                        <MenuItem sx={{ minHeight: 48, height: 48 }}>
                            <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary="Terms of Use" />
                            {hoverId === 'TOS' && (
                                <ListItemIcon
                                    onClick={handleAboutThisPlatformMenuClose}
                                    style={{ minWidth: 20, width: 20 }}
                                >
                                    <OpenInNewOutlinedIcon />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    </Link>
                    <Link
                        href="https://www.iubenda.com/privacy-policy/32505123"
                        target="_blank"
                        onMouseEnter={() => setHoverId('PP')}
                        onMouseLeave={() => setHoverId(null)}
                    >
                        <MenuItem sx={{ minHeight: 48, height: 48 }}>
                            <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary="Privacy Policy" />
                            {hoverId === 'PP' && (
                                <ListItemIcon
                                    onClick={handleAboutThisPlatformMenuClose}
                                    style={{ minWidth: 20, width: 20 }}
                                >
                                    <OpenInNewOutlinedIcon />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    </Link>
                    <Link
                        href="https://www.iubenda.com/privacy-policy/32505123/cookie-policy"
                        target="_blank"
                        onMouseEnter={() => setHoverId('CP')}
                        onMouseLeave={() => setHoverId(null)}
                    >
                        <MenuItem sx={{ minHeight: 48, height: 48 }}>
                            <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary="Cookie Policy" />
                            {hoverId === 'CP' && (
                                <ListItemIcon
                                    onClick={handleAboutThisPlatformMenuClose}
                                    style={{ minWidth: 20, width: 20 }}
                                >
                                    <OpenInNewOutlinedIcon />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    </Link>
                    <Link
                        href="https://knowledge.conferencecompass.com/release-notes "
                        target="_blank"
                        onMouseEnter={() => setHoverId('RN')}
                        onMouseLeave={() => setHoverId(null)}
                        sx={{ mb: 3 }}
                    >
                        <MenuItem sx={{ minHeight: 48, height: 48 }}>
                            <ListItemText primaryTypographyProps={{ variant: 'body1' }} primary="Release notes" />
                            {hoverId === 'RN' && (
                                <ListItemIcon
                                    onClick={handleAboutThisPlatformMenuClose}
                                    style={{ minWidth: 20, width: 20 }}
                                >
                                    <OpenInNewOutlinedIcon />
                                </ListItemIcon>
                            )}
                        </MenuItem>
                    </Link>
                    <Divider variant="middle" sx={{ mt: 1 }} />
                    <Typography variant="subtitle1" textAlign="center" px={3} py={2}>
                        We are here to help you get the most out of your time at an event, by building powerful,
                        engaging and easy-to-use online and mobile event apps.
                    </Typography>
                    <Stack direction="row" justifyContent="center" spacing={2} pb={2}>
                        <Link href="https://www.conferencecompass.com/" target="_blank">
                            <ConferenceCompassIcon />
                        </Link>
                        <Link href="https://www.facebook.com/ConferenceCompass" target="_blank">
                            <FacebookIcon />
                        </Link>
                        <Link href="https://twitter.com/ConfCompass" target="_blank">
                            <TwitterIcon />
                        </Link>
                        <Link href="https://www.linkedin.com/company/conferencecompass" target="_blank">
                            <LinkedinIcon />
                        </Link>
                    </Stack>
                    <Typography variant="subtitle1" textAlign="center">
                        Conference Compass
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center">
                        Binckhorstlaan 36, Unit M2. 18
                    </Typography>
                    <Typography variant="subtitle1" textAlign="center">
                        2516 BE, The Hague
                    </Typography>
                </Menu>
            </>
        );
    } else {
        return <LoginButton />;
    }
};
