import React from 'react';

const TwitterIcon = () => (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_24_125)">
            <rect x="0.5" y="0.500122" width="31" height="31" rx="15.5" fill="white" />
            <path
                d="M26 9.89367C25.2627 10.2159 24.4724 10.4323 23.6416 10.5305C24.4896 10.0302 25.1411 9.23845 25.4467 8.29628C24.6548 8.75815 23.7741 9.0942 22.8405 9.27527C22.0923 8.48962 21.0261 8.00012 19.8462 8.00012C17.5798 8.00012 15.742 9.80774 15.742 12.0389C15.742 12.355 15.7794 12.6634 15.8496 12.9596C12.4391 12.7908 9.41672 11.1826 7.39194 8.73821C7.03811 9.33665 6.83704 10.0302 6.83704 10.7699C6.83704 12.1708 7.56028 13.4076 8.6623 14.1334C7.98893 14.1119 7.35609 13.9293 6.80274 13.6271C6.80274 13.6439 6.80274 13.6608 6.80274 13.6777C6.80274 15.6357 8.21807 17.2668 10.0932 17.6397C9.75029 17.7318 9.38711 17.7809 9.01302 17.7809C8.74803 17.7809 8.49084 17.7563 8.23989 17.7072C8.76362 19.3108 10.2787 20.4816 12.0728 20.5138C10.6699 21.5971 8.90079 22.2416 6.97732 22.2416C6.64531 22.2416 6.31954 22.2232 6 22.1848C7.81436 23.3326 9.97163 24.0001 12.2895 24.0001C19.8368 24.0001 23.9643 17.8453 23.9643 12.5084C23.9643 12.3335 23.9596 12.157 23.9534 11.9852C24.7546 11.4159 25.4498 10.7039 26 9.89367Z"
                fill="black"
                fill-opacity="0.87"
            />
            <rect x="0.5" y="0.500122" width="31" height="31" rx="15.5" stroke="#DDDDDD" />
        </g>
        <defs>
            <clipPath id="clip0_24_125">
                <rect y="0.00012207" width="32" height="32" rx="6" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default TwitterIcon;
