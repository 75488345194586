import React from 'react';
import styled from 'styled-components';
import { ImageUploader } from '../inputs/ImageUploader';
import avatarPlaceholder from '../../../assets/images/avatar-placeholder.png';

const AvatarPickerWrapper = styled.div`
    width: 150px;
    height: 150px;
    position: relative;
`;

const ImageUploaderWrapper = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
`;

const CurrentImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
`;

const CurrentImage = styled.img`
    width: 100%;
    height: 100%;
`;

interface AvatarPickerProps {
    value: string | File;
    onChange: (file: File) => void;
}

export const AvatarPicker = ({ value, onChange }: AvatarPickerProps) => {
    const isFileType = typeof value === 'object';
    const src = isFileType ? URL.createObjectURL(value) : value;

    return (
        <AvatarPickerWrapper>
            <CurrentImageWrapper>
                <CurrentImage src={src || avatarPlaceholder} />
            </CurrentImageWrapper>
            <ImageUploaderWrapper>
                <ImageUploader onChange={onChange} />
            </ImageUploaderWrapper>
        </AvatarPickerWrapper>
    );
};
