import React from 'react';
import { Modal } from '../components';
import Typography from '@mui/material/Typography';

type Props = {
    children: JSX.Element;
};

export const CookieEnabledWrapper: React.FC<Props> = ({ children }) => {
    const isCookieEnabled = navigator.cookieEnabled;

    const refreshPage = () => {
        window.location.reload();
    };

    if (!isCookieEnabled) {
        return (
            <div>
                <Modal
                    title="Cookies are disabled"
                    onClose={() => console.log('You are not allowed to use the app without enabling cookies')}
                    actions={[
                        {
                            label: 'Try again',
                            onClick: refreshPage,
                        },
                    ]}
                >
                    <Typography variant="body1">
                        Your browser has cookies disabled. Make sure that your cookies are enabled and try again
                    </Typography>
                </Modal>
            </div>
        );
    }

    return <div>{children}</div>;
};
