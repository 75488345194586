const FLOQ_SERVICE_URL = process.env.REACT_APP_FLOQ_SERVICE_URL || 'https://api.floq.dev';

const AUTH0 = {
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'auth.floq.dev',
    client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || 'oPL3hO9NQxe6XpkQZ1D75IbzAoSEwcVY',
    audience: process.env.REACT_APP_AUTH0_AUDIENCE || 'floq-api',
    organization: process.env.REACT_APP_AUTH0_ORGANIZATION || 'org_3LaSusAsXkwrwOyz',
};
const FLOQ_STUDIO_URL = process.env.REACT_APP_FLOQ_STUDIO_URL || 'https://studio.floq.dev';

export { AUTH0, FLOQ_SERVICE_URL, FLOQ_STUDIO_URL };
